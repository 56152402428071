.home {
  &__coming-soon-mac {
    margin-top: -16%;
  }

  &2 {
    transform: translateY(+1rem);
  }

  &5 {
    transform: translateX(4rem);
  }

  &4 {
    &-text-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-top-image {
    transform: translateY(-1rem);

    &__2 {
      transform: translateY(-10rem);
    }
  }
}

@media (max-width: 768px) {
  .home {
    &__coming-soon-mac {
      margin-top: 0 !important;
    }
  }
}